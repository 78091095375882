
import { defineComponent, ref, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import { DataItem } from '@/types/app.global';
import { SimpleTableExposeType } from '@/types/types.config';

import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import { AuditOutlined } from '@ant-design/icons-vue';

/**
 * 线下打款
 */
export default defineComponent({
  name: 'OfflinePayment',
  components: { FormModal, SearchTable, AuditOutlined },
  setup() {
    const { userMeta } = useGetters();
    const { https, mapper, message } = useInject();
    const tableRef = ref<SimpleTableExposeType>();
    const tableOpts = {
      selection: {
        type: 'radio'
      },
      search: {
        criteria: {
          cols: [
            {
              field: 'orderCode',
              label: '订单编号'
            },
            {
              field: 'status',
              label: '状态',
              type: 'select',
              options: mapper?.mall.offlinePaymentStatus
            },
          ]
        },
        remote: {
          action: https?.mall.pageOfflinePayment
        }
      },
      cols: [
        {
          field: 'orderCode',
          label: '订单编号',
          ability: 'copy'
        },
        {
          field: 'col-basic',
          label: '基础信息',
          width: 160,
          group: [
            {
              field: 'money',
              label: '金额'
            },
            {
              field: 'status',
              label: '状态',
              options: mapper?.mall.offlinePaymentStatus
            },
            {
              field: 'type',
              label: '类型',
              options: mapper?.mall.offlinePaymentType
            },
          ]
        },
        {
          field: 'col-payer',
          label: '打款人信息',
          width: 300,
          group: [
            {
              field: 'payer',
              label: '账户'
            },
            {
              field: 'payerCardNo',
              label: '卡号'
            },
            {
              field: 'payerBank',
              label: '银行'
            }
          ]
        },
        {
          field: 'col-rec',
          label: '收款人信息',
          width: 300,
          group: [
            {
              field: 'recName',
              label: '账户'
            },
            {
              field: 'recCardNo',
              label: '卡号'
            },
            {
              field: 'recBank',
              label: '银行'
            }
          ]
        },
        {
          field: 'col-audit',
          label: '审核信息',
          width: 220,
          group: [
            {
              field: 'creater',
              label: '制单人'
            },
            {
              field: 'updater',
              label: '审核人'
            },
            {
              field: 'comment',
              label: '审核意见'
            },
          ]
        },
        {
          field: 'path1',
          label: '打款凭证',
          ability: 'preview',
          width: 100,
          format: (val: string, data: DataItem) => {
            let str = '';
            if (val) {
              str += val;
            }
            if (data.path2) {
              str += ',' + data.path2;
            }
            if (data.path3) {
              str += ',' + data.path3;
            }
            return str;
          }
        },
        {
          field: 'col-time',
          label: '时间',
          width: 220,
          group: [
            {
              field: 'createTime',
              label: '创建'
            },
            {
              field: 'updateTime',
              label: '更新'
            }
          ]
        }
      ]
    };

    // 审核
    const approvalOpts = reactive({
      title: '线下打款审核',
      visible: false,
      action: https?.mall.auditOfflinePayment,
      // onSuccess: (data: DataItem) => {
      //   tableRef.value?.expose.replace(data);
      // },
      content: {
        entity: {} as DataItem,
        cols: [
          {
            field: 'orderCode',
            label: '订单编号',
            disabled: true
          },
          {
            field: 'status',
            label: '审核意见',
            type: 'radio',
            options: mapper?.mall.offlinePaymentAuditType,
            required: true
          },
          {
            field: 'comment',
            label: '备注',
          },
        ]
      }
    });

    const openApproval = () => {
      const row = tableRef.value?.expose.getSelectedData()[0];
      if (!row) {
        message?.warn('请选择一条数据！');
        return;
      }

      if (row.status !== '0') {
        message?.warn('已审核，请勿重复操作！');
        return;
      }

      approvalOpts.content.entity = { id: row.id, orderCode: row.orderCode, status: '', comment: '', approvalId: userMeta.uid };
      approvalOpts.visible = true;
    };

    return { tableRef, tableOpts, approvalOpts, openApproval };
  }
});
